import { createReducer } from '@reduxjs/toolkit'

import { setEventsList, setFormatList, setSelectedCity } from '../action'

const initialState = {
  selectedCity: null,
  events: null,
  formats: [],
}

const app = createReducer(initialState, (builder) => {
  builder
    .addCase(setSelectedCity, (state, action) => {
      state.selectedCity = action.payload
    })
    .addCase(setEventsList, (state, action) => {
      state.events = action.payload
    })
    .addCase(setFormatList, (state, action) => {
      state.formats = action.payload
    })
})

export { app }

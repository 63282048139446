import { createAction } from '@reduxjs/toolkit'

const ActionType = {
  REDIRECT_TO_ROUTE: 'app/redirectToRoute',
  SELECT_CITY: 'app/selectCity',
  SET_EVENTS_LIST: 'app/eventsList',
  SET_FORMAT_LIST: 'app/formatList',
}

const setSelectedCity = createAction(ActionType.SELECT_CITY, (city) => ({
  payload: city,
}))

const setEventsList = createAction(ActionType.SET_EVENTS_LIST, (events) => ({
  payload: events,
}))

const setFormatList = createAction(ActionType.SET_FORMAT_LIST, (events) => ({
  payload: events,
}))

export { ActionType, setSelectedCity, setEventsList, setFormatList }

import 'lazysizes'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import MainPage from '@/pages/MainPage/MainPage'
import { fetchEventsList } from '@/store/api-actions'
import { getEventsList } from '@/store/app/selectors'

import './App.css'

function App() {
  const dispatch = useDispatch()
  const events = useSelector(getEventsList)

  useEffect(() => {
    !events && dispatch(fetchEventsList())
  }, [])

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />

      {events?.map((event) => (
        <Route
          path={`/${event.public_id}`}
          key={event.public_id}
          element={<MainPage cityIdScreen={event.public_id} />}
        />
      ))}
    </Routes>
  )
}

export default App

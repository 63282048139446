import { configureStore } from '@reduxjs/toolkit'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import { YMInitializer } from 'react-yandex-metrika'

import api from './api'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { redirect } from './store/middlewares/redirect'
import rootReducer from './store/root-reducer'

const basename = process.env.PUBLIC_URL

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
    }).concat(redirect),
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Suspense fallback="loading">
    <BrowserRouter basename={basename}>
      <Provider store={store}>
        <YMInitializer accounts={[91868814]} options={{ webvisor: false }} />
        <App />
      </Provider>
    </BrowserRouter>
  </Suspense>
)

reportWebVitals()

import { lazy } from 'react'
import Marquee from 'react-double-marquee'
import { useMediaQuery } from 'react-responsive'

import textImg from '@/assets/images/webp/text-img.webp'

import styles from './MainPage.module.scss'

const About = lazy(() => import('@/components/About/About'))
const Corp = lazy(() => import('@/components/Corp/Corp'))
const CupHaskiMo = lazy(() => import('@/components/CupHaskiMo/CupHaskiMo'))
const Faq = lazy(() => import('@/components/Faq/Faq'))
const Features = lazy(() => import('@/components/Features/Features'))
const Formats = lazy(() => import('@/components/Formats/Formats'))
const Online = lazy(() => import('@/components/Online/Online'))
const Options = lazy(() => import('@/components/Options/Options'))
// const Partners = lazy(() => import('@/components/Partners/Partners'))
const Routes = lazy(() => import('@/components/Routes/Routes'))
const StarterPack = lazy(() => import('@/components/StarterPack/StarterPack'))
const TopPage = lazy(() => import('@/components/TopPage/TopPage'))
const Map = lazy(() => import('@/components/Map/Map'))

function MainPage({ cityIdScreen }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <>
      <TopPage cityIdScreen={cityIdScreen} />
      <div className={styles.linesWrap}>
        <div className={styles.marqueWrap}>
          <Marquee>
            <p className={styles.lineText}>
              <span className={styles.lineText1}>Любительская лыжная гонка</span> &#8226;{' '}
              <span className={styles.lineText2}>Любительская лыжная гонка</span> &#8226;{' '}
              <span className={styles.lineText1}>Любительская лыжная гонка</span> &#8226;{' '}
              <span className={styles.lineText2}>Любительская лыжная гонка</span> &#8226;{' '}
              <span className={styles.lineText1}>Любительская лыжная гонка</span> &#8226;{' '}
              <span className={styles.lineText2}>Любительская лыжная гонка</span> &#8226;
            </p>
          </Marquee>
        </div>
      </div>

      <div className={styles.linesBg}>
        <About />
        <Features />
        <Routes city={cityIdScreen} />
        <StarterPack />
        <span className={styles.line1} />
        <span className={styles.line2} />
        <span className={styles.line3} />
        <span className={styles.line4} />
        <span className={styles.line5} />
        {!isMobile && (
          <div className={styles.textImgWrap}>
            <img className={`${styles.textImg} lazyload`} src={textImg} alt="" />
          </div>
        )}
      </div>
      <Online />
      <Formats />
      <Options />
      <CupHaskiMo cityIdScreen={cityIdScreen} />
      {/* <Partners /> */}
      <Corp />
      <Faq />
      <Map />
    </>
  )
}

export default MainPage

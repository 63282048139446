import axios from 'axios'

export const HERO_API = process.env.REACT_APP_API
export const PUBLIC_URL = process.env.PUBLIC_URL
const TIMEOUT = 5000

export default axios.create({
  baseURL: HERO_API,
  timeout: TIMEOUT,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})
